import React from 'react'
import { useIntl } from "gatsby-plugin-intl"
import YoutubeRecentJA from '../../hooks/use-youtube-recent-JA.js'
import YoutubeRecentEN from '../../hooks/use-youtube-recent-EN.js'
import Img from "gatsby-image"
import useAllImgs from "../../hooks/use-all-image"
import snsStyles from './css/sns.module.styl'
const { figureWrap, generalFadeIn, logo, logoWrap, sns, } = snsStyles

const SNS = () => {
    const intl = useIntl()
    const language = intl.locale

    const youtubeDateJA = YoutubeRecentJA()
    const youtubeDateEN = YoutubeRecentEN()

    const images = useAllImgs()
    const youtubeLogo = images.find(
        img => img.childImageSharp.fluid.originalName === "yt_logo_mono_dark.png"
    )

    return (
        <section className={sns}>
            {language === 'ja' &&
                <>
                    <div className={figureWrap}>
                        {youtubeDateJA.map(data => (
                            <a key={data.id} href={`https://youtu.be/${data.videoId}`} target='_blank'>
                                <figure>
                                    <Img fluid={data.localThumbnail.childImageSharp.fluid} />
                                    <figcaption>{data.title}</figcaption>
                                </figure>
                            </a>

                        ))}
                    </div>
                    <div className={logoWrap}>
                        <a href="https://www.youtube.com/channel/UCoxOKSbRGkZSNR7no2-7U9g" target="_blank">
                            <Img className={logo} fluid={youtubeLogo.childImageSharp.fluid} />
                        </a>
                    </div>
                </>
            }
            {language === 'en' &&
                <>
                    <div className={figureWrap}>
                        {youtubeDateEN.map(data => (
                            <a key={data.id} href={`https://youtu.be/${data.videoId}`} target='_blank'>
                                <figure>
                                    <Img fluid={data.localThumbnail.childImageSharp.fluid} />
                                    <figcaption>{data.title}</figcaption>
                                </figure>
                            </a>

                        ))}
                    </div>
                    <div className={logoWrap}>
                        <a href="https://www.youtube.com/channel/UC0GkSvJXy0YyXEDw6IDNObw" target="_blank">
                            <Img className={logo} fluid={youtubeLogo.childImageSharp.fluid} />
                        </a>
                    </div>
                </>
            }
        </section>
    )
}


export default SNS