import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import useAwardList from "../hooks/use-award-list"
import awardStyles from "./css/award.module.styl"
const { award, emp, generalFadeIn, num, } = awardStyles

const Award = () => {
  const intl = useIntl()
  const language = intl.locale
  const awardList = useAwardList()

  return (
    <section className={`history ${award} component`}>
      {awardList.map(list => (
        <h2 key={list._id}>
          <span className={emp}>{list.emp[language]}</span>
          <br className="sp" />
          <span>{list.normal[language]}</span>
        </h2>
      ))}
    </section>
  )
}

export default Award
