import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import MainVisuals from '../../hooks/use-mainvisual-image';
import mainVisualStyles from "./css/mainVisual.module.styl"
import {
  mainVisual,
  subtitle,
  selectMainVisual,
  popUpText,
} from "./data/data-mainVisual"
import downArrow from "../../images/svg/down-arrow.svg"
// import disableScroll from "disable-scroll";
const {
  active,
  arrowPC,
  count,
  cover,
  description,
  generalFadeIn,
  location,
  mainVisual: mainVisualCSS,
  mainVisualCotainer,
  open,
  popUp: popUpCSS,
  subtitle: subtitleCSS,
  text,
  title } = mainVisualStyles

function MainVisual() {
  const [state, setState] = useState({
    selectedMV_Number: selectMainVisual(), //selectMainVisual()
    descriptionOpen: false,
    popUp: false,
  })

  const mainVisuals = MainVisuals()
  const mainVisualToday = mainVisuals[state.selectedMV_Number]

  const handleArrow = () => {
    let descriptionOpen = state.descriptionOpen
    setState({ ...state, descriptionOpen: !descriptionOpen })
  }

  const handlePopUp = () => {
    setState({ ...state, popUp: true })
    setTimeout(() => {
      setState({ ...state, popUp: false })
    }, 3000)
  }

  //

  let mainVisualData = mainVisual[state.selectedMV_Number]

  const intl = useIntl()
  const language = intl.locale

  const { descriptionOpen, popUp } = state

  //

  return (
    <section className={mainVisualCSS}>
      <div className={mainVisualCotainer + (descriptionOpen ? ` ${open}` : "")}>
        <Img fluid={mainVisualToday.childImageSharp.fluid} />
      </div>
      <p className={subtitleCSS + (descriptionOpen ? ` ${open}` : "")}>
        {subtitle}
      </p>
      <ul className={text + (descriptionOpen ? ` ${open}` : "")}>
        <li className={title} onClick={handleArrow}>
          {mainVisualData.title[language]}
          <img onClick={handleArrow} src={downArrow} alt="" />
        </li>
        <li className={location}>{mainVisualData.location[language]}</li>
        <li className={description}>{mainVisualData.description[language]}</li>
        <li className={cover}></li>
        <img className={arrowPC} onClick={handleArrow} src={downArrow} alt="" />
      </ul>
      <p className={count}>
        <span className={popUpCSS + (popUp ? ` ${active}` : "")}>
          {popUpText[language]}
        </span>
        <span onMouseOver={handlePopUp} onClick={handlePopUp}>
          {state.selectedMV_Number + 1}/{mainVisual.length}
        </span>
      </p>
    </section>
  )
}

export default MainVisual
