import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import useMobileAppText from "../../hooks/use-mobileApp-text"
import "./css/mobileApp.styl"

import bnrAppStore from "../../images/svg/bnr-app-store.svg"
import bnrGooglePlay from "../../images/svg/bnr-google-play.svg"
import imgApp from "../../images/svg/img-app.svg"
import video_sp_jp from "../../videos/video_sp_jp.mp4"
import video_sp_en from "../../videos/video_sp_en.mp4"
import video_tb_jp from "../../videos/video_tb_jp.mp4"
import video_tb_en from "../../videos/video_tb_en.mp4"

// videoへの画像（poster）設置は自動再生されないことがあるためキャンセル
import poster_sp_en from "../../videos/img-poster/poster_sp_en.png"
import poster_sp_jp from "../../videos/img-poster/poster_sp_jp.png"
import poster_tb_en from "../../videos/img-poster/poster_tb_en.png"
import poster_tb_jp from "../../videos/img-poster/poster_tb_jp.png"

const videos = {
  sp: { en: video_sp_en, ja: video_sp_jp },
  tablet: { en: video_tb_en, ja: video_tb_jp },
  posterSp: { en: poster_sp_en, ja: poster_sp_jp },
  posterTablet: { en: poster_tb_en, ja: poster_tb_jp },
}

function MobileApp(props) {
  const sentences = useMobileAppText()[0]

  const intl = useIntl()
  const language = intl.locale

  const [videoState, setVideoState] = useState({
    videoPlay: false,
    videoType: "mobile",
  })

  const handleVideoType = type => {
    setVideoState({ videoType: type })
  }

  // const ga_AppStoreAccess = type => {
  //   if (type === "ios") {
  //     language === "ja"
  //       ? Event("iOS App JP", "Access Japanese Apple Store", "APPLE_STORE_JP")
  //       : Event("iOS App EN", "Access English Apple Store", "APPLE_STORE_EN")
  //   } else {
  //     language === "ja"
  //       ? Event(
  //         "Android JP App",
  //         "Access Japanese Google Play Store",
  //         "GOOGLE_PLAY_JP"
  //       )
  //       : Event(
  //         "Android EN App",
  //         "Access English Google Play Store",
  //         "GOOGLE_PLAY_EN"
  //       )
  //   }
  // }

  return (
    <section className="mobileApp component">
      <div className="wrap">
        <div className="text">
          <h1>
            <img className="imgApp" src={imgApp} alt="App icon" />
            <span>{sentences.title[language]}</span>
          </h1>
          <p className={language}>{sentences.explain[language]}</p>
        </div>
        <div className="movie">
          {/* ref={this.movie} */}
          <figure>
            <video
              className={
                videoState.videoType === "mobile"
                  ? "mobile active"
                  : "tablet active"
              }
              loop
              autoPlay
              muted
              playsInline
              src={
                videoState.videoType === "mobile"
                  ? videos.sp[language]
                  : videos.tablet[language]
              }
              type="video/mp4"
              poster={
                videoState.videoType === "mobile"
                  ? videos.posterSp[language]
                  : videos.posterTablet[language]
              }
            >
            </video>

          </figure>
          <p>
            <span
              onClick={() => handleVideoType("mobile")}
              className={
                videoState.videoType === "mobile" ? "mobile active" : "mobile"
              }
            >
              mobile
            </span>{" "}
            /{" "}
            <span
              onClick={() => handleVideoType("tablet")}
              className={
                videoState.videoType === "tablet" ? "tablet active" : "tablet"
              }
            >
              tablet
            </span>
          </p>
        </div>
        <figure className="banner">
          <p className="price">
            <span className="strike_through">{sentences.price[language]}</span>
            {` `}&#8594;
            <span className="emp">{sentences.discountedPrice[language]}</span>
            {sentences.unit[language]} (70%off)
          </p>
          <OutboundLink
            href={sentences.links.ios[language]}
            target="_blank"
            rel="noreferrer noopener"
          // onClick={() => ga_AppStoreAccess("ios")}
          >
            <img src={bnrAppStore} alt="Apple Store" />
          </OutboundLink>
          <OutboundLink
            href={sentences.links.android[language]}
            target="_blank"
            rel="noreferrer noopener"
          // onClick={() => ga_AppStoreAccess("android")}
          >
            <img src={bnrGooglePlay} alt="Google Play" />
          </OutboundLink>
        </figure>
      </div>
    </section>
  )
}

export default MobileApp
