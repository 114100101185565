import React, { useState } from "react"
import { getStatement, getComments, title } from "../../data/statement"
import { useIntl } from "gatsby-plugin-intl"

import downArrow from "../../images/svg/down-arrow.svg"
import statementStyles from "./css/statement.module.styl"
const {
  avatar,
  card,
  cardWrap,
  comment: commentStyle,
  comments: commentsStyle,
  cover,
  generalFadeIn,
  jobTitle,
  kagaya,
  mori,
  name,
  nameWrap,
  open,
  statement: statementStyle } = statementStyles

function Statement() {
  const [state, setState] = useState({ statementOpen: false })
  const intl = useIntl()
  const language = intl.locale

  const handleStatement = () => {
    const currentState = state.statementOpen
    setState({ statementOpen: !currentState })
  }

  const statement = getStatement()
  const comments = getComments()

  return (
    <>
      <section className={`${statementStyle} ${state.statementOpen && open}`}>
        <h3>{title[language]}</h3>
        <p>{statement[language]}</p>
        <div className={cover}></div>
        <img onClick={handleStatement} src={downArrow} alt="" />
      </section>
      <section className={commentsStyle}>
        {comments.map(comment => (
          <div key={comment._id} className={cardWrap}>
            <div className={card}>
              <div className={commentStyle}>{comment[language]}</div>
              <figure className={`${avatar} ${comment.avatarName === 'kagaya' ? kagaya : mori}`}></figure>
              <div className={nameWrap}>
                <p className={name}>{comment.name[language]}</p>
                <p className={jobTitle}>{comment.jobTitle[language]}</p>
              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  )
}

export default Statement
