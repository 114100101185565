import { useStaticQuery, graphql } from "gatsby"

const YoutubeRecentEN = () => {
  const { allYoutubeVideo } = useStaticQuery(graphql`
    query {
      allYoutubeVideo(
          limit: 9,
          filter: {channelId: {eq: "UC0GkSvJXy0YyXEDw6IDNObw"}}) {
        nodes {
          channelId
          id
          title
          description
          videoId
          publishedAt
          privacyStatus
          channelTitle
          localThumbnail {
            childImageSharp {
              fluid (maxWidth: 320){
                ...GatsbyImageSharpFluid 
              }
            }
          }
        }
      }
    }
  `)

  return allYoutubeVideo.nodes
}

export default YoutubeRecentEN
