import { useStaticQuery, graphql } from "gatsby"

const useAwardList = () => {
  const { allAwardJson } = useStaticQuery(
    graphql`
      query {
        allAwardJson {
          nodes {
            _id
            emp {
              en
              ja
            }
            normal {
              en
              ja
            }
          }
        }
      }
    `
  )

  return allAwardJson.nodes
}

export default useAwardList
