import React from 'react'
import { useIntl, Link } from "gatsby-plugin-intl"
import { title, article, button, video } from '../../data/archive-top'
import archiveTopStyles from './css/archive.module.styl'
import imgApp from "../../images/svg/img-app.svg"
const { archiveTop, article: articleCSS, en, generalFadeIn, video: videoCSS, wrap, } = archiveTopStyles

const ArchiveTop = () => {
    const intl = useIntl()
    const language = intl.locale

    return (
        <section className={archiveTop}>
            <div className={wrap}>
                <h1>
                    <img className="imgApp" src={imgApp} alt="App icon" />
                    <span>{title.title[language]}</span>
                </h1>
                <div className={videoCSS}>
                    <iframe
                        src={video[language]}
                        frameBorder="0"
                        // width="700"
                        height="220"
                        allow="fullscreen"
                        allowFullScreen></iframe>

                </div>
                <ul className={language === 'ja' ? articleCSS : `${articleCSS} ${en}`}>
                    {language === 'ja' && <li className='book'>
                        <h3>{title.book[language]}</h3>
                        <p>{article.book[language]}</p>
                        <button>
                            <Link to='/archive'>{button[language]}</Link>
                        </button>
                    </li>}
                    <li className='archiveSite'>
                        {language === 'ja' && <h3>{title.archiveSite[language]}</h3>}
                        <p>{article.archiveSite[language]}</p>
                        <button>
                            <Link to='/archive'>{button[language]}</Link>
                        </button>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default ArchiveTop