export const mainVisual = [
  {
    id: 0,
    title: { en: "Hornet nest", ja: "スズメバチの巣" },
    location: {
      en: "Namie Town (Dec. 2018) / 1200 cpm",
      ja: "浪江町昼曽根（2018年12月） ／ 1200 cpm"
    },
    description: {
      en:
        "On this autoradiograph, we can see a lot of stripes of this nest. It shows that each stripe has a different level of radiation, which was caused because worker hornet collected the materials for building their nest from tree barks in forests and each bark had different levels of radiation so that the stripes on the autoradiograph reflect the contamination level of each bark.\n\nThe mountain area in Fukushima hasn't been decontaminated since 2011. Thus, hornets are collecting their materials with a high level of radiation from 2011's radioactive fallout. Hornets make their nest every summer very close to human living space like under the roof or in a garage. It tells that hornets continue to bring a high level of radiation to town every year unless we decontaminated the mountain area. This is an example of secondary spreading of contamination by animals/insects activity.",
      ja:
        "茨城キリスト教大学の昆虫学者 桑原先生と浪江町昼曽根を調査していると114号線の高架下にきれいな球形をした直径40cmほどのスズメバチの巣を発見した。スズメバチの巣の3次元放射線像を制作するために、以前からきれいな形をした巣を探していたのだ。桑原先生からこのスズメバチの巣をお借りして、3次元放射線像を制作したのがこの像である。\n\n放射線像を見ると、スズメバチの巣の縞模様が浮かび上がっている。これは、スズメバチの巣は森林の樹皮を材料にして働き蜂が唾液と混ぜて幾重にも塗り付けることで作られるので、木の一本一本に汚染の濃淡がそのまま反映された結果である。放射線量も1000cpm以上で高い値を示している。\n\nスズメバチの巣は、民家の軒先や物置に作られる。人間の生活圏の非常に近いところに毎年春から夏の短い間に突如として現れるのである。これは生物の生態活動による汚染拡散であり、除染した後の土地や家屋の再汚染の一例である。森林が除染されない限り、この拡散は継続する。\n\nわれわれはこれまでネズミの糞による室内の汚染や、イノシシやサルの糞によって汚染が大きく移動することを示してきた。今回、生物の生態活動による汚染拡散をもう一例新たに加えることができ、福島第一原発周辺の自治体の方々にも参考にしていただければと思う。\n\nこの3次元放射線像を制作するに当たり、スズメバチの巣の外被を27区画に分割し切り出し、7枚のイメージングプレートを使って撮像を繰り返した。"
    }
  },
  {
    id: 1,
    title: { en: "School helmet", ja: "通学用ヘルメット" },
    location: {
      en:
        "Obori Namie Town (Sept. 2017) / Top of head - 760 cpm, Brim - 1000 cpm",
      ja: "浪江町大堀（2017年9月） ／ 頭頂部 - 760 cpm、つば - 1000 cpm"
    },
    description: {
      en:
        "After 11 exposures over 33 days, we finished building up a 3D Autoradiograph from this school helmet. Even after being subjected to rainfall for six and half years, the insoluble radioactive caesium from the primary radioactive fallout still remains on the top of the helmet. Meanwhile, we can see that soluble caesium had flowed onto the brim where it became fixed. The Autoradiograph and the radiation measurements (cpm) clearly show that the brim is contaminated to a higher extent than the other parts.",
      ja:
        "6年半もの間、雨ざらしの状態で庭に放置されていたが、初期のフォールアウトで付着した不溶性放射性物質が頭頂部を中心に残っていることが確認できる。つばには雨によって周囲から可溶性の放射性物質が流れ込み、乾燥して定着した汚染が写し出されている。放射線量もつばの部分がもっとも高い値を示している。"
    }
  },
  // {
  //   id: 2,
  //   title: { en: "T-shirt", ja: "Tシャツ" },
  //   location: {
  //     en: "Futaba town（Dec. 2018） / 240 ~ 10600 cpm",
  //     ja: "双葉町（2018年12月） ／ 240 ~ 10600 cpm"
  //   },
  //   description: {
  //     en:
  //       "This sample was highly radioactive and we measured 10600cpm around the left lower abdomen. We can see several high contaminated spots which don't contain insoluble radioactive particles. We haven't seen before this type of contamination and we are curious about how it came from and what the origin it is.",
  //     ja:
  //       "約8年間、物干し竿にかかった状態で放置されていたTシャツである。この場所の空間線量は2018年12月時点で5～6μSv/h。放射線像を見ると、大きな円形の汚染が多数見つかった。最も汚染が強かったのは、胸側の右下腹部にある円形の汚染で10600cpmを計測した。その他は、可溶性・不溶性の放射性物質が分布していることが分かる。\n\nTシャツの文字や模様が浮かび上がっているのは、放射能汚染ではなく、この部分に蛍光塗料が使われていたためと考えられる。"
  //   }
  // },
  {
    id: 2,
    title: { en: "Cypress leaves and cones", ja: "ヒノキの葉と実" },
    location: {
      en: "Iitate Village (2014)",
      ja: "飯舘村飯樋（2014年）"
    },
    description: {
      en:
        "White parts dispersed throughout the proximal end of the branch are highly contaminated by the fallout in March 2011. The branches that grew out from those parts in 2012 exhibit slight secondary contamination by radioactive particles carried by the wind, while the white hue emitted around the cones that developed in 2013 indicate a remarkable level of contamination. Further, the young leaves that grew in 2014 have higher internal radioactive contamination than the other leaves. In another cypress, we found that while the leaves and cones had the same level of contamination, the seeds in the cones had one third the contamination thereof, indicating that the radioactive caesium is being passed on to the next generation.",
      ja:
        "植林されたヒノキがたくさんの実をつけていたので、それを引きちぎって来た。この図で切断された部位の枝が放射能で濃厚汚染しているのは、この枝が2011年3月に直接フォールアウトを受けたことを示している。そこから上の枝は2012，2013，2014年と伸張してきたもので、そこに付いた汚染は風雨による二次的な外部汚染と思われる。2013年の赤茶色の球果が強く汚染していることは非常に印象的である。このサンプルではないが他のヒノキの測定値から、ヒノキの球果の殻は葉と同じ放射性セシウムの濃度であり、中の種子はその3分の1の濃度であった。放射性セシウムの次世代への移行が示されているわけである。"
    }
  }
];

export const subtitle = "“ We should make it visible ”";

export const popUpText = {
  en: "This Autoradiograph is changed once a day.",
  ja: "1日に1回、この放射線像は切り替わります。"
};

export function selectMainVisual() {
  const today = Date.now();
  const days = Math.floor(today / (1000 * 60 * 60 * 24));
  const number = mainVisual.length;
  return days % number;
}
