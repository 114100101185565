import { priceList } from './price-list';

export const title = {
  title: {
    en: 'Archive app',
    ja: 'ハイブリッド出版'
  },
  book: {
    en: 'Book',
    ja: '本'
  },
  archiveSite: {
    en: 'Archive app',
    ja: 'アーカイブサイト'
  }
}

export const video = {
  en: "https://player.vimeo.com/video/489729351?autoplay=1&loop=1&muted=1",
  ja: "https://player.vimeo.com/video/461976908?autoplay=1&loop=1&muted=1"
}

export const article = {
  book: {
    en: `Including strictly selected 51 autoradiographs`,
    ja: `厳選された51点の放射線像を収録。本＋アーカイブサイトのセット購入をお薦めしています。もちろん本のみの購入も可能です。`
  },
  archiveSite: {
    en: `This app includes all of archives (more than 170 works) and can display them both on desktop and mobile. You can manipulate 3D Autoradiographs, enlarge and compare with original samples' color photos and also check where it was sampled on Google map. Please take a look at examples from the link below.`,
    ja: `170以上のすべての放射線像を収録。 サンプルのカラー写真と対比、3次元放射線像の操作と全視野からの観察などすべての機能が備わっています。`
  }
}
//（${priceList[0].price.ja}円）

export const button = {
  en: 'More details',
  ja: '詳しくはこちら'
}
