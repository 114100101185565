import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Award from "../components/award"
import MainVisual from "../components/top/mainVisual"
import MobileApp from "../components/top/mobileApp"
import Statement from "../components/top/statement"
import SNS from '../components/top/sns';
import "../utils/userAgent"
import ArchiveTop from '../components/top/archive';

import "./css/top.styl"
// import LanguageAdapt from "../components/languageAdapt"

const IndexPage = ({ location }) => {
  const intl = useIntl()
  const language = intl.locale

  return (
    <Layout location={location} noMargin={true} top={true}>
      <SEO
        lang={intl.locale}
        title={"top"}
        keywords={[`放射線像`, `Autoradiograph`]}
        // keywords={language === 'ja' ?
        //   [`放射線像`, `加賀谷 雅道`, `森　敏`] :
        //   [`Autoradiograph`, `Masamichi Kagaya`, `Satoshi Mori`]}
        location={location}
      />
      <section className="top">
        <MainVisual />
        <Award />
        <ArchiveTop />
        {/* <MobileApp /> */}
        <Statement />
        <SNS />
      </section>
    </Layout>
  )
}

export default IndexPage
